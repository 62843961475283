<template>
    <div class="antialiased">
        <main>
            <slot/>
        </main>
    </div>
</template>

<script setup>

</script>
